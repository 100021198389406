
<template>
    <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Quality Control Pemda Provinsi</h3>
            <div class="card-toolbar">

            </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0">
            <!--begin::Item-->
            <div class="mb-12">
                <!--begin::Content-->
                <div>

                    <div style="overflow-x:auto;">
                        <table id="example" class="table table-striped table-bordered"
                            style="border-collapse: collapse;width:100%">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama Provinsi</th>
                                    <th>Nama QC</th>
                                    <th>Nama Pendamping QC</th>
                                    <th>Nama PIC Tim TA</th>
                                    <th>Nama PIC Tim IT</th>
                                    <th>Aksi</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataProvinsi" :key="index">
                                    <td></td>
                                    <td>
                                        <div style="width: 250px;">
                                        {{ data.daerah }}
                                    </div>
                                    </td>
                                    <td>
                                        <div style="width: 250px;">
                                            <b-form-select v-model="data.id_tim_qc" size="sm" style="max-width:200px">
                                            <b-form-select-option v-for="(value, name) in userQC" :key="name"
                                                :value="value.id">{{ value.name }} </b-form-select-option>
                                        </b-form-select>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="width: 250px;">
                                            <b-form-select v-model="data.id_pendamping_qc" size="sm" style="max-width:200px">
                                                <b-form-select-option v-for="(value, name) in userPendampingQC" :key="name"
                                                    :value="value.id">{{ value.name }} </b-form-select-option>
                                            </b-form-select>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="width: 250px;">
                                        <b-form-select v-model="data.pic_tim_ta" :options="optionTimTA" size="sm" style="max-width:200px"></b-form-select>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="width: 250px;">
                                        <b-form-select v-model="data.pic_tim_it" :options="optionTimTI" size="sm" style="max-width:200px"></b-form-select>
                                        </div>
                                    </td>
                                    <td>
                                        <b-button variant="primary" @click="simpanMapping(data)">Simpan</b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--end::Content-->
            </div>
            <!--end::Item-->
        </div>
        <!--end: Card Body-->
    </div>
</template>
<script>
import Vue from 'vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Loading', // default 'Loading'
    loading: true, // default false
    //customLoader: myVueComponent, // replaces the spinner and text with your own
    background: 'rgb(255,255,255)', // set custom background
    classes: ['myclass'] // array, object or string
})
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
// import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";

export default {
    name: "mapping",
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        // 15th two months prior
        const minDate = new Date('2023-01-24')

        // 15th in two months
        const maxDate = new Date('2023-08-13')
        return {
            urlpenilaian: localStorage.getItem('baseapipenilaian'),
            url: localStorage.getItem('baseapi'),
            token: localStorage.getItem('id_token'),
            user: JSON.parse(localStorage.getItem('user')),
            dataSurveyorProvinsi: {},
            dataSurveyor: [],
            dataSurveyorKl: [],
            loader: null,
            loading: false,
            test: 'hai',
            id_user: '',
            roles: [],
            search: '',
            provinsi: [],
            kota: [],
            tanggal_mulai: '',
            tanggal_selesai: '',
            source_user_id: '',
            surveyor: [],
            headers: [
                {
                    text: 'Nama',
                    align: 'left',
                    sortable: false,
                    value: 'nama_wilayah',
                },
                { text: 'Tanggal', value: '' },
                { text: 'Nama Surveyor', value: '' },
                { text: 'Aksi', value: '' }

            ],
            optionTimTA:['Aris Budi Setyawan','Gatot Rambi Hastoro','Much Nur Achmad','Mukhlis Abidi','Arip Rahman S','Budiana'],
            optionTimTI:['Sigit Widiyanto','Miftah Andriansyah','Moh. Yunus','Hadyan Taris Akbar','Alfredo Sahat M.','Singgih Wisnu Pranata','Ghifari Prameswari','Luna Adam','Sayidati Karimah','Nur Ika','Linda Handayani', 'Vira Yolanda','Tegar Arif Topan','Idha Dwi Permatasari','Fattah Al Ilmi Suhendra'],
            iduserrr: '',
            min: minDate,
            max: maxDate,

            userQC: [],
            userPendampingQC: [],
            dataProvinsi: []
        }
    },
    components: {
        // AdvancedTableWidget2,
        // AdvancedTableWidget3,
        // MixedWidget1,
        // ListWidget1,
        // ListWidget3,
        // ListWidget4,
        // ListWidget8,
        // ListWidget9,
        // StatsWidget7,
        // StatsWidget12,
        // StatsWidget13
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mapping" }]);
        this.getUserQC();
        this.getUserPendampingQC();
        this.loadData();

    },
    methods: {
        loadData() {
            $('#example').DataTable().destroy();
            axios.get(this.url + `/rekapitulasi_qc_pemda?filter=perlu_qc,=,true;jenis_pemda,=,Provinsi;tahun,=,2024`, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
                this.dataProvinsi = response.data.data
                this.initDatatable();
            })
        },

        getUserQC() {
            axios.get(this.url + `/users?filter=id_role,=,qc`, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
                this.userQC = response.data.data;

            });
        },
        getUserPendampingQC() {
            axios.get(this.url + `/users?filter=id_role,=,pendamping_qc`, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
                this.userPendampingQC = response.data.data;

            });
        },
        simpanMapping(data) {
            var formData = {
                id_tim_qc: data.id_tim_qc,
                id_pendamping_qc: data.id_pendamping_qc,
                pic_tim_ta: data.pic_tim_ta,
                pic_tim_it: data.pic_tim_it,
            }
            axios.put(this.url + '/rekapitulasi_qc_pemda/' + data.id,
                formData,
                {
                    headers: {
                        'xth': this.token
                    }
                }).then(response => {
                    this.$bvToast.toast('Berhasil Mapping', {
                        title: `Berhasil`,
                        variant: 'success',
                        solid: true
                    })
                    this.loadData()
                    return response;
                }).catch(err => {
                    this.$bvToast.toast('Gagal Mapping', {
                        title: `Gagal`,
                        variant: 'danger',
                        solid: true
                    })
                    return err
                })
        },
        setActiveTab1(event) {
            this.tabIndex = this.setActiveTab(event);
        },
        setActiveTab2(event) {
            this.tabIndex2 = this.setActiveTab(event);
        },
        /**
         * Set current active on click
         * @param event
         */
        setActiveTab(event) {
            // get all tab links
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set current active tab
            event.target.classList.add("active");

            // set clicked tab index to bootstrap tab
            return parseInt(event.target.getAttribute("data-tab"));
        },
        initDatatable() {
            setTimeout(() => {
                var t = $('#example').DataTable({
                    "pagingType": "full_numbers",
                    "language": {
                        "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                        "paginate": {
                            "first": "Awal",
                            "last": "Akhir",
                            "next": "Selanjutnya",
                            "previous": "Sebelumnya"
                        },
                    },
                    scrollX: true,
                    fixedColumns: {
                        left: 2,
                    },
                    dom: 'Bfrtip',
                    // "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    //     //debugger;
                    //     var index = iDisplayIndexFull + 1;
                    //     $("td:first", nRow).html(index);
                    //     return nRow;
                    //   },
                    // columnDefs: [{
                    //   targets: 0,
                    //   searchable: false,
                    //   orderable: false,
                    //   render: function(data, type, row, meta) {
                    //   console.log(meta.row);
                    //   // console.log(type == 'export'? meta.row : data);
                    //   //return type == 'export'? meta.row + 1 : data;
                    //   return meta.row + 1
                    //   }
                    // }],
                    buttons: [
                    ],
                    "lengthMenu": [
                        [10, 25, 50, -1],
                        [10, 25, 50, "All"]
                    ],
                    order: [[1, 'asc']],
                    responsive: true,
                    destroy: true,
                    retrieve: true,
                    autoFill: true,
                    colReorder: true,
                    columnDefs: [
                        { searchable: false, targets: 2 },
                        { searchable: false, targets: 3 },
                        { searchable: false, targets: 4 },
                        { searchable: false, targets: 5 }
                    ]

                });
                // t.on( 'order.dt search.dt', function () {
                // t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                //     cell.innerHTML = i+1;
                // } );
                // } ).draw();
                t.on('order.dt search.dt', function () {
                    let i = 1;

                    t.cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
                        this.data(i++);
                    });
                }).draw();
            }, 300)
        },
    },
    created() {
        //   this.getuser(this.user)
        // this.getlocaldata()
    }
};
</script>
